import { useEffect, useState, useRef } from 'react'
import { Grid, IconButton, Box } from '@mui/material';
import styles from './index.module.scss';
import Icon from '@mdi/react';
import { mdiChevronUp, mdiChevronDown } from '@mdi/js';
import BannerLayer from '../BannerLayer/index';
import FootLayer from '../FootLayer/index';
import abilityCard from '../../assets/ToC/abilityCard.svg';
import abilityCard_phone from '../../assets/ToC/abilityCard_phone.svg';
import courseCard from '../../assets/ToC/courseCard.svg';
import courseCard_phone from '../../assets/ToC/courseCard_phone.svg';
import knowleageCard from '../../assets/ToC/knowleageCard.svg';
import knowleageCard_phone from '../../assets/ToC/knowleageCard_phone.svg';
import markCard from '../../assets/ToC/markCard.svg';
import markCard_phone from '../../assets/ToC/markCard_phone.svg';
import chart from '../../assets/ToC/chart.svg';
import chart_phone from '../../assets/ToC/chart_phone.svg';
import blueCard_1 from '../../assets/ToC/blueCard_1.svg';
import blueCard_1_phone from '../../assets/ToC/blueCard_1_phone.svg';
import useCard_1 from '../../assets/ToC/useCard_1.svg';
import useCard_1_phone from '../../assets/ToC/useCard_1_phone.svg';
import blueCard_2 from '../../assets/ToC/blueCard_2.svg';
import blueCard_2_phone from '../../assets/ToC/blueCard_2_phone.svg';
import useCard_2 from '../../assets/ToC/useCard_2.svg';
import useCard_2_phone from '../../assets/ToC/useCard_2_phone.svg';
import blueCard_3 from '../../assets/ToC/blueCard_3.svg';
import blueCard_3_phone from '../../assets/ToC/blueCard_3_phone.svg';
import useCard_3 from '../../assets/ToC/useCard_3.svg';
import useCard_3_phone from '../../assets/ToC/useCard_3_phone.svg';
import shareCard from '../../assets/ToC/shareCard.svg';
import shareCard_phone from '../../assets/ToC/shareCard_phone.svg';
import component_card_toeic_test from '../../assets/ToC/component-card_toeic_test.svg';
import component_card_toeic_Vocabulary from '../../assets/ToC/component-card_toeic_Vocabulary.svg';
import GiftBox from "../GiftBox/index"
import useScreen from "../../hooks/screen";
import { Helmet } from 'react-helmet';

const CardScroll = () => {
  const screenWidth = useScreen();

  return (
    <div style={{ position: "relative" }}>
      <div className={styles.title} style={{ width: "100%", position: "absolute", top: screenWidth > 768 ? "-40px" : "-60px" }}>
        <h1>如何使用?</h1>
      </div>
      <div>
        <section className={styles.infoSection}>
          <div className={styles.featureContainer}>
            <div className={styles.featureCardScrollWrapper_1}>
              <div className={styles.featureCardWrapper_1}>
                <div className={styles.featureCard_1}>
                  {screenWidth > 768 ?
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img src={useCard_3} className={styles.useImg_web_1} />
                    </div>
                    :
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img src={useCard_3_phone} style={{ maxWidth: "350px" }} />
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className={styles.featureCardScrollWrapper_2}>
              <div className={styles.featureCardWrapper_2}>
                <div className={styles.featureCard_2}>
                  {screenWidth > 768 ?
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img src={useCard_2} className={styles.useImg_web_2} />
                    </div>
                    :
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img src={useCard_2_phone} style={{ maxWidth: "350px" }} />
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className={styles.featureCardScrollWrapper_3}>
              <div className={styles.featureCardWrapper_3}>
                <div className={styles.featureCard_3}>
                  {screenWidth > 768 ?
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img src={useCard_1} className={styles.useImg_web_3} />
                    </div>
                    :
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img src={useCard_1_phone} style={{ maxWidth: "350px" }} />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

  );

};

const FAQ = () => {
  const screenWidth = useScreen();
  const aboutAIText = [
    {
      title: "如何開始使用 TAG EASY？",
      content: "您可以直接註冊會員，立即享有免費7天的使用資格。"
    },
    {
      title: "TAG EASY 是完全免費的嗎？",
      content: ""
    },
    {
      title: "無法登入我的帳戶，該怎麼辦？",
      content: ""
    },
    {
      title: "TAG EASY 何時會提供我想學習語言的課程或支援我的偏好語言呢？",
      content: ""
    },
    {
      title: "我還有其他問題！",
      content: ""
    }
  ];

  const [chevronUpAI, setChevronUpAI] = useState(Array(aboutAIText.length).fill(false));

  const handleChevronAI = (index) => {
    const newChevronUp = [...chevronUpAI];
    newChevronUp[index] = !newChevronUp[index];
    setChevronUpAI(newChevronUp);
  };

  return (
    <>
      <div className={styles.Wrap} style={{ margin: "30px 0px" }}>
        <Grid className={styles.Card}>
          <Box className={styles.CardTitleContainer}>
            {aboutAIText.map((item, index) => (
              <div
                key={index}
                style={{
                  width: "100%", // 確保每個區塊的寬度固定
                  // maxWidth: "600px", // 設定一個最大寬度
                  minWidth: "300px", // 設定最小寬度
                  wordWrap: "break-word", // 防止文本溢出
                  // padding:"0px 8%"
                }}
              >
                <div style={{ display: "flex", justifyContent: "space-between", margin: "10px 0px" }}>
                  <p style={{ marginBottom: "5px", fontWeight: "700", fontSize: "24px" }}>{item.title}</p>
                  <IconButton
                    className={styles.btn_questionCount}
                    onClick={() => handleChevronAI(index)}
                    sx={{ padding: 0 }}
                  >
                    {chevronUpAI[index] ? (
                      <Icon path={mdiChevronUp} size={0.8} color="#757575" />
                    ) : (
                      <Icon path={mdiChevronDown} size={0.8} color="#757575" />
                    )}
                  </IconButton>
                </div>
                {chevronUpAI[index] && (
                  <div style={{ paddingBottom: "20px" }}>
                    <span style={{ color: "#757575", fontSize: "16px" }}>
                      {item.content}
                    </span>
                  </div>
                )}
                <hr />
              </div>
            ))}
          </Box>
        </Grid>
      </div>
    </>
  );
};

const Section_1 = () => {
  const screenWidth = useScreen();
  const handleLogin = () => {
    if (process.env.REACT_APP_ENV_NAME === "dev") {
      window.open('https://toeic-dev.tageasy.com.tw/', '_blank');
    } else {
      window.open('https://toeic.tageasy.com.tw/', '_blank');
    }
  }
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className={styles.section_1_wrap}>
        <div className={styles.title} style={{ display: "block" }}>
          <h1>免費單字與模擬試題</h1>
          <span style={{ color: "#757575", fontWeight: "600" }}>免費註冊使用帳號，就能立即使用擬真多益試題及多益單字練習。</span>
        </div>
        {
          screenWidth > 768 ?
            <div style={{ display: "flex", padding: "20px 0px" }}>
              <a className="btn_register" onClick={() => handleLogin()} style={{ marginRight: "20px", maxWidth: "539px", cursor: "pointer" }}>
                <img src={component_card_toeic_test} style={{ maxWidth: "539px" }} />
              </a>
              <a className="btn_register" onClick={() => handleLogin()} style={{ maxWidth: "539px", cursor: "pointer" }}>
                <img src={component_card_toeic_Vocabulary} style={{ maxWidth: "539px" }} />
              </a>
            </div>
            :
            <div style={{ display: "grid", justifyContent: "center", padding: "20px 0px" }}>
              <div onClick={() => handleLogin()} style={{ maxWidth: "328px", cursor: "pointer" }}>
                <img src={component_card_toeic_test} style={{ maxWidth: "328px" }} />
              </div>
              <div onClick={() => handleLogin()} style={{ maxWidth: "328px", cursor: "pointer" }}>
                <img src={component_card_toeic_Vocabulary} style={{ maxWidth: "328px" }} />
              </div>
            </div>
        }
        <div style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "50px" }}>
          <button className={`${styles.TrialButtonBox} btn_register`} onClick={() => handleLogin()} >
            立即免費註冊
          </button>
        </div>
      </div>
    </div >
  )
}

const Section_2 = () => {
  const screenWidth = useScreen();

  return (
    <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
      <div className={styles.section_1_wrap}>
        <div className={styles.title} style={{ marginTop: "0px" }}>
          <h1>為何選擇 TAG EASY?</h1>
        </div>
        {
          screenWidth > 768 ?
            <div style={{ display: "flex", padding: "20px 1%", marginBottom: "20px" }}>
              <div style={{ marginRight: "20px", maxWidth: "539px" }}>
                <img src={abilityCard} style={{ marginBottom: "20px", maxWidth: "539px" }} />
                <img src={knowleageCard} style={{ maxWidth: "539px" }} />
              </div>
              <div style={{ maxWidth: "539px" }}>
                <img src={markCard} style={{ marginBottom: "20px", maxWidth: "539px" }} />
                <img src={courseCard} style={{ maxWidth: "539px" }} />
              </div>
            </div>
            :
            <div style={{ padding: "20px 2%", marginBottom: "20px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <img src={abilityCard_phone} style={{ marginBottom: "20px", maxWidth: "328px" }} />
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <img src={markCard_phone} style={{ marginBottom: "20px", maxWidth: "328px" }} />
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <img src={courseCard_phone} style={{ marginBottom: "20px", maxWidth: "328px" }} />
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <img src={knowleageCard_phone} style={{ marginBottom: "20px", maxWidth: "328px" }} />
              </div>
            </div>
        }
      </div>
    </div>
  )
}

const Section_3 = () => {

  return (
    <CardScroll />
  )
}

const Section_4 = () => {
  const screenWidth = useScreen();

  const handleLogin = () => {
    if (process.env.REACT_APP_ENV_NAME === "dev") {
      window.open('https://toeic-dev.tageasy.com.tw/', '_blank');
    } else {
      window.open('https://toeic.tageasy.com.tw/', '_blank');
    }
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className={styles.section_1_wrap}>
        <div className={styles.title} style={{ display: "grid" }}>
          <h1>幫您預測還需多久即能達標</h1>
          <h6>每次訓練課表完成後，AI 將即時計算並預測您的達標分數及次數。</h6>
        </div>
        {
          screenWidth > 768 ?
            <div style={{ padding: "20px 2%" }}>
              <img src={chart} style={{ maxWidth: "1102px" }} />
            </div>
            :
            <div style={{ padding: "20px 2%", width: "100%", display: "flex", justifyContent: "center" }}>
              <img src={chart_phone} style={{ maxWidth: "320px" }} />
            </div>
        }
        <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "10px" }}>
          <button className={`${styles.TrialButtonBox} btn_register`} onClick={() => handleLogin()}>
            立即免費註冊
          </button>
        </div>
        <div style={{ padding: "40px 2%" }}>
          <div style={{ width: "100%", display: screenWidth > 768 ? "flex" : "block", justifyContent: "center" }}>
            {
              screenWidth > 768 ?
                <>
                  <div style={{ width: "100%" }}><img src={blueCard_1} /> </div>
                  <div style={{ width: "100%", margin: "0px 10px" }}><img src={blueCard_2} /> </div>
                  <div style={{ width: "100%" }}><img src={blueCard_3} /> </div>
                </>
                :
                <>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center" }}><img src={blueCard_1_phone} style={{ maxWidth: "320px" }} /> </div>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: screenWidth > 768 ? "0px 10px" : "25px 0px" }}><img src={blueCard_2_phone} style={{ maxWidth: "320px" }} /> </div>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center" }}><img src={blueCard_3_phone} style={{ maxWidth: "320px" }} /> </div>
                </>
            }

          </div>
        </div>

      </div>
    </div>
  )
}

const Section_5 = () => {

  const screenWidth = useScreen();


  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className={styles.section_1_wrap} style={{ width: "100%" }}>
        <div className={styles.title}>
          <h1>好評推薦</h1>
        </div>
        <div style={{ padding: screenWidth > 768 ? "40px 2%" : "10px 0%", width: "100%", display: "flex", justifyContent: "center" }}>
          {
            screenWidth < 768 ?
              <img src={shareCard_phone} style={{ maxWidth: "320px" }} />
              : <img src={shareCard} style={{ maxWidth: "1102px" }} />
          }
        </div>
      </div>
    </div>
  )
}

const Section_6 = () => {
  const screenWidth = useScreen();

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className={styles.section_1_wrap} style={{ padding: screenWidth > 768 ? "0px" : '0px 6%', width: screenWidth > 768 ? "85%" : '' }}>
        <div className={styles.title}>
          <h1>常見問題解答</h1>
        </div>
        <div >
          <FAQ />
        </div>
      </div>
    </div>
  )
}

const Section_7 = () => {

  const handleLogin = () => {
    if (process.env.REACT_APP_ENV_NAME === "dev") {
      window.open('https://toeic-dev.tageasy.com.tw/', '_blank');
    } else {
      window.open('https://toeic.tageasy.com.tw/', '_blank');
    }
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", marginBottom: "80px" }}>
      <div className={styles.section_1_wrap} >
        <div className={styles.title}>
          <h1>AI 時代的英文學習法</h1>
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <button className={`${styles.TrialButtonBox} btn_register`} onClick={() => handleLogin()} >
            立即免費註冊
          </button>
        </div>
      </div>
    </div>
  )
}


function ToCPage() {
  const screenWidth = useScreen();

  return (
    <div style={{position:"relative"}}>
      <GiftBox />
      <Helmet>

        <meta name="description" content="想準備多益卻不知道從哪開始？刷了一本又一本多益考題，分數卻還是拉不起來？TAG EASY多益智慧學習平台，搭載獨門知識演算法，可以有效追蹤您的答題表現，智慧規劃每週的TOEIC學習時間，進行精準刷題，週週預測達成目標分數的時間。" />
      </Helmet>
      <BannerLayer url={"ToC"} />

      <div style={{ padding: screenWidth < 768 ? "0px 5%" : "0px 12%" }}>
        <Section_1 />
        <Section_2 />
        <Section_3 />
        <Section_4 />
        <Section_5 />
        {/* <Section_6 /> */}
        <Section_7 />
      </div>
      <FootLayer />
    </div>
  )
}

export default ToCPage;

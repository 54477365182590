import { useEffect, useState, useRef } from 'react'
import { Grid, IconButton, Box } from '@mui/material';
import styles from './index.module.scss';
import Icon from '@mdi/react';
import { mdiChevronUp, mdiChevronDown } from '@mdi/js';
import BannerLayer from '../BannerLayer/index';
import FootLayer from '../FootLayer/index';
import schedule_card from '../../assets/ToB/schedule_card.svg';
import analysis_card from '../../assets/ToB/analysis_card.svg';
import organize_card from '../../assets/ToB/organize_card.svg';
import schedule_card_phone from '../../assets/ToB/schedule_card_phone.svg';
import analysis_card_phone from '../../assets/ToB/analysis_card_phone.svg';
import organize_card_phone from '../../assets/ToB/organize_card_phone.svg';
import share_card from '../../assets/ToB/share_card.svg';
import promote_card from '../../assets/ToB/promote_card.svg';
import share_card_phone from '../../assets/ToB/share_card_phone.svg';
import promote_card_phone from '../../assets/ToB/promote_card_phone.svg';
import useScreen from "../../hooks/screen";
import { Helmet } from 'react-helmet';
import GiftBox from "../GiftBox/index"

const FAQ = () => {
  const screenWidth = useScreen();
  const aboutAIText = [
    {
      title: "如何開始使用 TAG EASY？",
      content: "您可以直接註冊會員，立即享有免費7天的使用資格。"
    },
    {
      title: "TAG EASY 是完全免費的嗎？",
      content: ""
    },
    {
      title: "無法登入我的帳戶，該怎麼辦？",
      content: ""
    },
    {
      title: "TAG EASY 何時會提供我想學習語言的課程或支援我的偏好語言呢？",
      content: ""
    },
    {
      title: "我還有其他問題！",
      content: ""
    }
  ];

  const [chevronUpAI, setChevronUpAI] = useState(Array(aboutAIText.length).fill(false));

  const handleChevronAI = (index) => {
    const newChevronUp = [...chevronUpAI];
    newChevronUp[index] = !newChevronUp[index];
    setChevronUpAI(newChevronUp);
  };

  return (
    <>
      <div className={styles.Wrap} style={{ margin: "30px 0px" }}>
        <Grid className={styles.Card}>
          <Box className={styles.CardTitleContainer}>
            {aboutAIText.map((item, index) => (
              <div
                key={index}
                style={{
                  width: "100%", // 確保每個區塊的寬度固定
                  // maxWidth: "600px", // 設定一個最大寬度
                  minWidth: "300px", // 設定最小寬度
                  wordWrap: "break-word", // 防止文本溢出
                  // padding:"0px 8%"
                }}
              >
                <div style={{ display: "flex", justifyContent: "space-between", margin: "10px 0px" }}>
                  <p style={{ marginBottom: "5px", fontWeight: "700", fontSize: "24px" }}>{item.title}</p>
                  <IconButton
                    className={styles.btn_questionCount}
                    onClick={() => handleChevronAI(index)}
                    sx={{ padding: 0 }}
                  >
                    {chevronUpAI[index] ? (
                      <Icon path={mdiChevronUp} size={0.8} color="#757575" />
                    ) : (
                      <Icon path={mdiChevronDown} size={0.8} color="#757575" />
                    )}
                  </IconButton>
                </div>
                {chevronUpAI[index] && (
                  <div style={{ paddingBottom: "20px" }}>
                    <span style={{ color: "#757575", fontSize: "16px" }}>
                      {item.content}
                    </span>
                  </div>
                )}
                <hr />
              </div>
            ))}
          </Box>
        </Grid>
      </div>
    </>
  );
};

const Section_1 = () => {
  const screenWidth = useScreen();

  return (
    <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
      <div className={styles.section_1_wrap}>
        <div className={styles.title}>
          <h1>透過 AI 智慧自動化提高您的團隊效率</h1>
        </div>
        {
          screenWidth > 768 ?
            <div style={{ display: "flex", padding: "30px 1%" }}>
              <div style={{ maxWidth: "351px" }}>
                <img src={schedule_card} style={{ maxWidth: "351px" }} />
              </div>
              <div style={{ maxWidth: "351px", margin:"0px 20px" }}>
                <img src={analysis_card} style={{ maxWidth: "351px" }} />
              </div>
              <div style={{ maxWidth: "351px" }}>
                <img src={organize_card} style={{ maxWidth: "351px" }} />
              </div>
            </div>
            :
            <div style={{ padding: "20px 2%" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <img src={schedule_card_phone} style={{ marginBottom: "20px", maxWidth: "351px" }} />
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <img src={analysis_card_phone} style={{ marginBottom: "20px", maxWidth: "351px" }} />
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <img src={organize_card_phone} style={{ marginBottom: "20px", maxWidth: "351px" }} />
              </div>
            </div>
        }
      </div>
    </div>
  )
}

const Section_2 = () => {
  const screenWidth = useScreen();

  const handleLogin = () => {
    window.location.href = 'https://www.colerate.com.tw/contact';
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", position:"relative", backgroundColor:"#004382" }}>
      <div className={styles.section_2_wrap}>
        {
          screenWidth > 768 ?
            <div>
              <img src={promote_card} />
            </div>
            :
            <div>
              <img src={promote_card_phone} />
            </div>
        }
        <div className={screenWidth > 768 ? styles.TrialButtonBox_section2 : styles.TrialButtonBox_section2_phone} >
          <div className={screenWidth > 768 ? styles.TrialButtonBox : styles.TrialButtonBox_phone} onClick={() => handleLogin()}>
            <div>預約體驗</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Section_3 = () => {

  const screenWidth = useScreen();


  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className={styles.section_3_wrap} style={{ width: "100%" }}>
        <div className={styles.title}>
          <h1>好評推薦</h1>
        </div>
        <div style={{ padding: screenWidth > 768 ? "40px 2%" : "10px 0%", width: "100%", display: "flex", justifyContent: "center" }}>
          {
            screenWidth < 768 ?
              <img src={share_card_phone} style={{ maxWidth: "320px" }} />
              : 
              <img src={share_card} style={{ maxWidth: "1102px" }} />
          }
        </div>
      </div>
    </div>
  )
}

const Section_4 = () => {

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className={styles.section_4_wrap}>
        <div className={styles.title}>
          <h1>常見問題解答</h1>
        </div>
        <FAQ />
      </div>
    </div>
  )
}

const Section_5 = () => {
  const handleLogin = () => {
    window.location.href = 'https://www.colerate.com.tw/contact';
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", marginBottom: "80px" }}>
      <div className={styles.section_5_wrap} >
        <div className={styles.title}>
          <h1>AI 時代的英文學習法</h1>
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <div className={styles.TrialButtonBox} onClick={() => handleLogin()} >
            <div>預約體驗</div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ToBPage() {
  const screenWidth = useScreen();

  return (
    <div style={{position:"relative"}}>
      <GiftBox type={"ToB"}/>
      <Helmet>
        <meta name="description" content="想準備多益卻不知道從哪開始？刷了一本又一本多益考題，分數卻還是拉不起來？TAG EASY多益智慧學習平台，搭載獨門知識演算法，可以有效追蹤您的答題表現，智慧規劃每週的TOEIC學習時間，進行精準刷題，週週預測達成目標分數的時間。" />
      </Helmet>
      <BannerLayer url={"ToB"} />
      <div style={{ padding: screenWidth < 768 ? "0px 5%" : "0px 12%" }}>
        <Section_1 />
      </div>
        <Section_2 />
      <div style={{ padding: screenWidth < 768 ? "0px 5%" : "0px 12%" }}>  
        <Section_3 />
        {/* <Section_4/> */}
        <Section_5/>
      </div>
      <FootLayer />
    </div>
  )
}

export default ToBPage;

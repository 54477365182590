import { useEffect, useState, useRef } from 'react'
import { Grid, IconButton, Box } from '@mui/material';
import styles from './index.module.scss';
import FootLayer from '../FootLayer/index';
import useScreen from "../../hooks/screen";
import { Helmet } from 'react-helmet';
import web_event_main_pic from '../../assets/Activity/web-event-main-pic.svg';
import web_event_main_title from '../../assets/Activity/web-event-main-title.svg';
import phone_event_main_pic from '../../assets/Activity/phone-event-main-pic.svg';
import phone_event_main_title from '../../assets/Activity/phone-event-main-title.svg';
import web_event_card_1 from '../../assets/Activity/web-event-card-1.svg';
import web_event_card_2 from '../../assets/Activity/web-event-card-2.svg';
import web_event_card_3 from '../../assets/Activity/web-event-card-3.svg';
import phone_event_card_1 from '../../assets/Activity/phone-event-card-1.svg';
import phone_event_card_2 from '../../assets/Activity/phone-event-card-2.svg';
import phone_event_card_3 from '../../assets/Activity/phone-event-card-3.svg';
import web_event_buying_card from '../../assets/Activity/web-event-buying-card.svg';
import phone_event_buying_card from '../../assets/Activity/phone-event-buying-card.svg';
import web_event_notice_card from '../../assets/Activity/web-event-notice-card.svg';
import phone_event_notice_card from '../../assets/Activity/phone-event-notice-card.svg';
import phone_event_3_gift_card from '../../assets/Activity/phone-event-3-gift-card.svg';
import web_event_3_gift_card from '../../assets/Activity/web-event-3-gift-card.svg';
import Icon from '@mdi/react';
import { mdiArrowULeftTop } from '@mdi/js';
import { useNavigate } from 'react-router-dom';

function ActivityPage() {
  const navigate = useNavigate();
  const screenWidth = useScreen();
  const [isWebTitleLoaded, setIsWebTitleLoaded] = useState(false);
  const [isWebPicLoaded, setIsWebPicLoaded] = useState(false);
  const [isPhoneTitleLoaded, setIsPhoneTitleLoaded] = useState(false);
  const [isPhonePicLoaded, setIsPhonePicLoaded] = useState(false);

  const handleWebTitleLoad = () => setIsWebTitleLoaded(true);
  const handleWebPicLoad = () => setIsWebPicLoaded(true);
  const handlePhoneTitleLoad = () => setIsPhoneTitleLoaded(true);
  const handlePhonePicLoad = () => setIsPhonePicLoaded(true);

  const allWebImagesLoaded = isWebTitleLoaded && isWebPicLoaded;
  const allPhoneImagesLoaded = isPhoneTitleLoaded && isPhonePicLoaded;
  
  const handleLogin = () => {
    if (process.env.REACT_APP_ENV_NAME === "dev") {
      window.open('https://toeic-dev.tageasy.com.tw/activity', '_blank');
    } else {
      window.open('https://toeic.tageasy.com.tw/activity', '_blank');
    }
  }

  const handleHomePage = () => {
    navigate("/");
  }
  return (
    <div style={{ backgroundColor: "#FECB68" }}>
      <Helmet>
        <meta name="description" content="想準備多益卻不知道從哪開始？現在註冊TAG EASY進行刷題，立即享七天免費核心功能體驗，還有三重好禮等你拿！" />
      </Helmet>
      <div style={{ position: "relative" }}>
        <div onClick={handleHomePage} style={{ position: "absolute", top: "20px", left: screenWidth > 768 ? "50px" : "10px", display: "flex", color: "#ffffff", cursor: "pointer" }}>
          <div style={{ marginRight: "5px" }}><Icon path={mdiArrowULeftTop} size={1} color={"#ffffff"} /></div>
          <span>返回 TAG EASY 首頁</span>
        </div>
      </div>
      {
        screenWidth > 600 ?
        <Box className={styles.web_event_main_Box}>
        {/* 隱藏圖片以便提前加載 */}
        <img src={web_event_main_title} onLoad={handleWebTitleLoad} style={{ display: 'none' }} />
        <img src={web_event_main_pic} onLoad={handleWebPicLoad} style={{ display: 'none' }} />

        {allWebImagesLoaded && screenWidth > 600 ? (
          <>
            <div className={styles.web_event_main_title}>
              <img src={web_event_main_title} alt="Web Main Title" />
              <div className={`${styles.TrialButtonBox} btn_register_AD`} onClick={handleLogin}>
                <div>立即加入</div>
              </div>
            </div>
            <div>
              <img src={web_event_main_pic} alt="Web Main Pic" />
            </div>
          </>
        ) : (
          <div style={{ height: '700px', width: '100%' }}></div> 
        )}
        </Box>
        :
        <Box className={styles.phone_event_main_Box}>

        <img src={phone_event_main_title} onLoad={handlePhoneTitleLoad} style={{ display: 'none' }} />
        <img src={phone_event_main_pic} onLoad={handlePhonePicLoad} style={{ display: 'none' }} />

        {allPhoneImagesLoaded && screenWidth <= 600 ? (
          <>
            <div className={styles.phone_event_main_title}>
              <img src={phone_event_main_title} alt="Phone Main Title" />
            </div>
            <div>
              <img src={phone_event_main_pic} alt="Phone Main Pic" />
            </div>
            <div className={`${styles.TrialButtonBox} btn_register_AD`} onClick={handleLogin}>
              <div>立即加入</div>
            </div>
          </>
        ) : (
          <div style={{ height: '700px', width: '100%' }}></div> 
        )}
        </Box>
      }

      {
        screenWidth > 600 ?
          <div>
            <div className={styles.event_3_gift_card_title} >
              <h1>好禮活動辦法</h1>
              <span>現在註冊TAG EASY，立即享七天免費核心功能體驗，還有三重好禮等你拿！</span>
            </div>
            <Box className={styles.web_event_card_Box} >
              <div>
                <img src={web_event_card_1} />
              </div>
              <div>
                <img src={web_event_card_2} />
              </div>
              <div>
                <img src={web_event_card_3} />
              </div>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "30px" }}>
              <button className={`${styles.TrialButtonBox} btn_register_AD`} style={{ width: "300px", height: "90px" }} onClick={() => handleLogin()}>
                <span style={{ fontSize: "27px" }}>立即參加</span>
              </button>
            </Box>
          </div>

          :
          <div>
            <div className={styles.event_3_gift_card_title} >
              <h1>好禮活動辦法</h1>
              <span>現在註冊TAG EASY，立即享七天免費核心功能體驗，還有三重好禮等你拿！</span>
            </div>
            <Box className={styles.phone_event_card_Box} >
              <div className={styles.phone_event_card_item} >
                <img src={phone_event_card_1} />
              </div>
              <div className={styles.phone_event_card_item} style={{ margin: "40px 0px" }}>
                <img src={phone_event_card_2} />
              </div>
              <div className={styles.phone_event_card_item} >
                <img src={phone_event_card_3} />
              </div>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "30px" }}>
              <button className={`${styles.TrialButtonBox} btn_register_AD`} style={{ width: "300px", height: "90px" }} onClick={() => handleLogin()}>
                <span style={{ fontSize: "27px" }}>立即參加</span>
              </button>
            </Box>
          </div>

      }

      <Box>
        <div className={styles.event_buying_card_Box} >
          <div className={styles.event_buying_card_title} >
            <h1>首購優惠辦法</h1>
            <span>如何讓多益備考輕鬆又高效？TAG EASY神隊友助你一臂之力！</span>
          </div>
          <a className={`${styles.event_buying_card_img} btn_register_AD`} onClick={() => handleLogin()}>
            {
              screenWidth > 600 ? <img src={web_event_buying_card} /> : <img src={phone_event_buying_card} />
            }
          </a>
        </div>
        <div className={styles.event_notice_card_Box} >
          <div className={styles.event_notice_card_img} >
            {
              screenWidth > 600 ? <img src={web_event_notice_card} /> : <img src={phone_event_notice_card} />
            }
          </div>
        </div>
      </Box>

      <FootLayer />
    </div>
  )
}

export default ActivityPage;

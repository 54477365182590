import { Routes, Route, useLocation } from "react-router-dom";
import HomePage from './components/HomePage/index';
import ToCPage from './components/ToCPage/index';
import ToSPage from './components/ToSPage/index';
import ToBPage from './components/ToBPage/index';
import ActivityPage from './components/ActivityPage/index';
import useGtmTracker from './hooks/gtmTracker';
import { useEffect } from "react";

function App() {
  const location = useLocation();
  useGtmTracker();

  useEffect(() => {
    switch (location.pathname) {
      case "/activity/202409":
        document.title = "專屬優惠399元，再享好禮三重送";
        break;
      default:
        document.title = "TAG EASY";
    }
  }, [location.pathname]);

  return (
    <div>
      <Routes>
        <Route path="" element={<HomePage />} />
        <Route path="*" element={<HomePage />} />
        <Route path="/toeic" element={<ToCPage />} />
        <Route path="/school" element={<ToSPage />} />
        <Route path="/business" element={<ToBPage />} />
        <Route path="/activity/202409" element={<ActivityPage />} />
      </Routes>
    </div>

  )
}

export default App

import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import useScreen from "../../hooks/screen";
import gift_box_body from "../../assets/gift-box-body@3x.png";
import gift_box_cover_on from "../../assets/gift-box-cover-on@3x.png";
import gift_box_cover_off from "../../assets/gift-box-cover-off@3x.png";
import gift_bubble_info from "../../assets/gift-bubble-info@3x.png";
import btn_close_gift_info from "../../assets/btn-close-gift-info@3x.png";





function GiftBox({type}) {
    const screenWidth = useScreen();
    const navigate = useNavigate();
    const [openGift, setOpenGift]= useState(true);

    const handleCloseGiftMsg = () =>{
      setOpenGift(false);
    }

    const handleOpenGiftMsg = () =>{
      setOpenGift(!openGift);
    }

    const handleActivityPage = () =>{
        window.scrollTo(0,0);
        navigate("/activity/202409");
    }
    

  return (
    <div style={{position:"fixed", top:"50%", right:screenWidth > 768 ? "7%":"12%", zIndex:"1000"}}>
        <div style={{position:"relative", width:"100px"}}>
            <div style={{ cursor: "pointer" }} onClick={handleOpenGiftMsg}>
            <img src={gift_box_body} style={{ position: "absolute",top:screenWidth > 768 ? "10px" : "-18px", right:screenWidth > 768 ? "-80px": "-40px", width: screenWidth > 768 ? "159px" : "106px", zIndex: "1001" }} />
            </div>
        {
            !openGift &&
            <div>
                <img src={gift_box_cover_off} style={{ position: "absolute", right:screenWidth > 768 ? "-82px":"-40px", top: screenWidth > 768 ? "7px" : "-18.5px", width: screenWidth > 768 ? "159px" : "106px", zIndex: "1000" }} />
            </div>
        }
        {
            openGift &&
            <>
                <div>
                    <img src={gift_box_cover_on} style={{ position: "absolute", right:screenWidth > 768 ? "-78px":"-40px", top: screenWidth > 768 ? "10px" : "-18.5px", width: screenWidth > 768 ? "159px" : "106px", zIndex: "1000" }} />
                </div>
            </>
        }
            <div
            className={`${styles.giftBubbleContainer} ${openGift ? styles.giftBubbleInfoOpen : styles.giftBubbleInfoClose}`}
            style={{ pointerEvents: openGift ? "auto" : "none" }}
            >
                <div style={{ cursor: "pointer" }} onClick={handleActivityPage}>
                    <img
                    src={gift_bubble_info}
                    className={styles.giftBubbleInfo}
                    style={{ width: screenWidth > 768 ? "225px" : "150px" }}
                    />
                </div>
                <div
                    style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: screenWidth > 768 ? "-10%" : "-28%",
                    left: "-3%"
                    }}
                    onClick={handleCloseGiftMsg}
                >
                    <img src={btn_close_gift_info} style={{ width: screenWidth > 768 ? "36px" : "24px" }} />
                </div>
            </div>
        </div>
    </div>

  )
}

export default GiftBox;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Box,
  IconButton
} from "@mui/material"
import styles from './index.module.scss';
import footer_logo from "../../assets/footer_logo.svg";
import benefit_1 from "../../assets/benefit_1.svg";
import benefit_2 from "../../assets/benefit_2.svg";
import benefit_3 from "../../assets/benefit_3.svg";
import useScreen from "../../hooks/screen";
import Icon from '@mdi/react';
import { mdiFacebook, mdiYoutube } from '@mdi/js';

const FootLayer = () => {
  const screenWidth = useScreen();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  let totalIndex = 2;
  const currentYear = new Date().getFullYear();
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex === totalIndex) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  const handlePage = (index) => {
    setCurrentIndex(index);
  }

  const handleLink = (type) => {
    window.scrollTo(0, 0);
    if (type === "ToC") {
      navigate("/toeic");
    } else if (type === "ToS") {
      navigate("/school");
    } else if (type === "ToB") {
      navigate("/business");
    } else if (type === "Home") {
      navigate("/");
    }
  }

  const handleFaceBook = () =>{
    window.open('https://www.facebook.com/profile.php?id=61566264441475', '_blank')
  }

  const handleYoutube = () =>{
    window.open('https://www.youtube.com/@COLERATE', '_blank')
  }

  // 導至隱私條款頁面
  const handleIntroductionPage = (type) => {
    if (process.env.REACT_APP_ENV_NAME === "dev") {
      window.location.href = `https://toeic-dev.tageasy.com.tw/introduction/terms&privacy#${type}`;
    } else {
      window.location.href = `https://toeic.tageasy.com.tw/introduction/terms&privacy#${type}`;
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.footWrap}>
        {/* {currentIndex === 0 ?
            <Grid>
              <Grid className={styles.footTitle}><h1>學生的收穫</h1></Grid>
              <Grid container className={styles.contentBox}>
                <Grid item md={6} sm={12} className={styles.leftContent}> 
                  <div>
                    <h2>全面性的目標計畫</h2>
                    <h1>省時精準且顯著高效</h1>
                    <span>藉由 AI 輔助挑選重要的題目，產生針對弱點的完整學習計畫，每次計畫根據當前狀態即時更新，有效率的達到學習目標，省下重複作答熟練題目的時間。</span>
                  </div>
                </Grid>
                <Grid item md={6} sm={12} className={styles.rightContent}>
                  <div>
                    <img src={benefit_1} className={styles.benefit_1}/>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          :null}
         {currentIndex === 1 ?
            <Grid>
              <Grid className={styles.footTitle}><h1>教師的好處</h1></Grid>
              <Grid container className={styles.contentBox}>
                <Grid item md={6} sm={12} className={styles.leftContent}> 
                  <div>
                    <h2>節省大量時間</h2>
                    <h1>透過分析即時關心</h1>
                    <span>清晰綜觀群體發展，不漏接最需要關心的學生，讓老師即刻反應並調整授課方針，展現更專業更好的備課教學。</span>
                  </div>
                </Grid>
                <Grid item md={6} sm={12} className={styles.rightContent}>
                  <div>
                    <img src={benefit_2} className={styles.benefit_2}/>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          :null}
          {currentIndex === 2 ?
            <Grid>
              <Grid className={styles.footTitle}><h1>靈活彈性</h1></Grid>
              <Grid container className={styles.contentBox}>
                <Grid item md={6} sm={12} className={styles.leftContent}> 
                  <div>
                    <h2>最佳的數位備考平台</h2>
                    <h1>適用 90% 以上考試與題型</h1>
                    <span>常見的語言類、文史類，或是其他科目，只要是具備正確解答的測驗，皆可使用。不管是聽力題、口說題、或是圖片題，都能在TAG EASY上完美呈現。</span>
                  </div>
                </Grid>
                <Grid item md={6} sm={12} className={styles.rightContent}>
                  <div>
                    <img src={benefit_3} className={styles.benefit_3}/>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          :null}
          <Grid>
            {Array.from({ length: totalIndex + 1 }).map((_, index) => (
  
              <svg
                key={index}
                width={currentIndex === index ? '24' : '8'}
                height={currentIndex === index ? '8' : '8'}
                viewBox={currentIndex === index ? '0 0 24 8' : '0 0 8 8'}
                fill="none"
                style={{ margin: '0px 5px', cursor:"pointer" }}
                onClick={()=> handlePage(index)}
              >
                <rect
                  width={currentIndex === index ? '24' : '8'}
                  height={currentIndex === index ? '8' : '8'}
                  rx={currentIndex === index ? '4' : '4'}
                  fill={currentIndex === index ? '#36A0DA' : 'white'}
                />
              </svg>
            ))}
          </Grid> */}
        <Grid>
          <Grid container className={styles.footInfo} spacing={2} >
            <Grid item xs={screenWidth > 768 ? 5 : 12} className={styles.footInfoLeft} sx={{ paddingLeft: "0px !important", display: "flex", justifyContent: "end", paddingRight: "28px" }}>
              <div>
                <div><img src={footer_logo} className={styles.footer_logo} onClick={() => handleLink("Home")} /></div>
                <div><span style={{width:"250px", display:"flex", fontSize:"12px", color:"#FFFFFF", marginTop:"10px"}}>TAG EASY整合學習內容與AI技術，追蹤並預測使用者的學習表現，提供學習規劃與動態推題，並預測達標時間，幫助使用者用最短的時間，最少的練習，獲得最佳學習成效。</span></div>
              </div>
            </Grid>
            <Grid item xs={screenWidth > 768 ? 2 : 12} className={styles.footInfoCenter} sx={{ paddingLeft: "0px !important", display:"grid", alignItems:"center" }}>
              <div style={{textAlign:"center"}}>
                <div onClick={() => handleLink("ToC")} style={{ fontSize: "14px" }}>了解多益刷題</div>
                <div onClick={() => handleLink("ToS")} style={{ margin: "10px 0px", fontSize: "14px" }}>關於校園方案</div>
                <div onClick={() => handleLink("ToB")} style={{ fontSize: "14px" }}>洽談企業合作</div>
              </div>
            </Grid>
            <Grid item
              xs={screenWidth > 768 ? 2 : 12}
              className={screenWidth > 768 ? styles.footInfoRight_1 : styles.footInfoRight}
              sx={{
                paddingLeft: screenWidth > 768 ? "30px !important" : "0px !important",
                paddingTop: screenWidth > 768 ? "16px !important" : "10px !important",
                display:"grid",
                alignItems:"center"
              }}>
                <div>
                  <span
                  onClick={() => handleIntroductionPage("terms")}
                  className={styles.footInfoRight_link}
                  style={{ marginBottom: "10px",fontSize:"14px" }}>
                  服務條款</span>
                <span
                  onClick={() => handleIntroductionPage("privacy")}
                  className={styles.footInfoRight_link}
                  style={{ marginBottom: "10px",fontSize:"14px" }}>
                  隱私政策</span>
                </div>
            </Grid>
            <Grid item
              xs={screenWidth > 768 ? 3 : 12}
              className={screenWidth > 768 ? styles.footInfoRight_2 : styles.footInfoRight}
              sx={{
                paddingLeft: "0px !important",
                paddingTop: screenWidth > 768 ? "0px !important" : "40px !important",
                color: "#FFFFFF",
                alignSelf:"center"
              }}>
              <div style={{display:"flex", justifyContent: screenWidth > 768 ? "left" : "center"}}>
              <IconButton
                    className={styles.btn_questionCount}
                    onClick={() => handleFaceBook()}
                    sx={{ padding: 0, marginRight:"15px" }}
                  >
                <Icon path={mdiFacebook} size={1}  color={"#FFFFFF"}/>

                  </IconButton>
                  <IconButton
                    className={styles.btn_questionCount}
                    onClick={() => handleYoutube()}
                    sx={{ padding: 0 }}
                  >
                <Icon path={mdiYoutube} size={1}  color={"#FFFFFF"}/>

                  </IconButton>
              </div>
              <div>
                <span style={{display:"block", textAlign:screenWidth> 768 ? "left" : "center", alignItems:"end", fontSize:"15px", margin:"10px 0px"}}>customersupport@colerate.com.tw</span>
                <span style={{padding: screenWidth > 768 ? "0px" :"0px 20%", display:"block", textAlign:screenWidth > 900 ? "left" : "center", fontSize:"15px", marginBottom:screenWidth > 768 ? "0px" : "10px"}}>{`Copyright © ${currentYear} Colerate Technology. All rights reserved.`}</span>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default FootLayer;

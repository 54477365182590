import React, { useState, useEffect } from "react";
import styles from './index.module.scss';
import { useSwipeable } from "react-swipeable";
import useScreen from "../../hooks/screen";

export const CarouselItem = ({ children, width }) => {
  return (
    <div className={styles.carousel_item} style={{ width: width }}>
      {children}
    </div>
  );
};

const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);
  const screenWidth = useScreen();

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1), 
    onSwipedRight: () => updateIndex(activeIndex - 1), 
  });

  useEffect(() => {
    const interval = setInterval(() => {
      updateIndex(activeIndex + 1);
    }, 6000);
  
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [activeIndex]);
  

  return (
    <div
      {...handlers} 
      className={styles.carousel}
      onMouseEnter={() => setPaused(true)} 
      onMouseLeave={() => setPaused(false)}
    >
      <div className={styles.inner} style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
      {
        screenWidth > 600 &&
        <div className={styles.indicators}>
          {React.Children.map(children, (child, index) => {
            return (
              <button
                className={`${index === activeIndex ? styles.active : ""}`}
                style={{cursor:"pointer"}}
                onClick={() => {
                  updateIndex(index);
                }}
              />
            );
          })}

        </div>
      }
    </div>
  );
};

export default Carousel;

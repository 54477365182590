import { useEffect, useState, useRef } from 'react';
import { Box, Drawer, List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
 } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import useScreen from "../../hooks/screen";
import HomeBannerImg_360 from "../../assets/HomeBannerImg_360.svg";
import HomeBannerImg_768 from "../../assets/HomeBannerImg_768.svg";
import HomeBannerImg_1440 from "../../assets/HomeBannerImg_1440.svg";
import HomeBannerImg_1920 from "../../assets/HomeBannerImg_1920.svg";
import pic_toC_inside_page_banner_360 from "../../assets/pic_toC_inside_page_banner_360.svg";
import pic_toC_inside_page_banner_768 from "../../assets/pic_toC_inside_page_banner_768.svg";
import pic_toC_inside_page_banner_1440 from "../../assets/pic_toC_inside_page_banner_1440.svg";
import pic_toC_inside_page_banner_1920 from "../../assets/pic_toC_inside_page_banner_1920.svg";
import pic_toS_inside_page_banner_360 from "../../assets/pic_toS_inside_page_banner_360.svg";
import pic_toS_inside_page_banner_768 from "../../assets/pic_toS_inside_page_banner_768.svg";
import pic_toS_inside_page_banner_1440 from "../../assets/pic_toS_inside_page_banner_1440.svg";
import pic_toS_inside_page_banner_1920 from "../../assets/pic_toS_inside_page_banner_1920.svg";
import pic_toB_inside_page_banner_360 from "../../assets/pic_toB_inside_page_banner_360.svg";
import pic_toB_inside_page_banner_768 from "../../assets/pic_toB_inside_page_banner_768.svg";
import pic_toB_inside_page_banner_1440 from "../../assets/pic_toB_inside_page_banner_1440.svg";
import pic_toB_inside_page_banner_1920 from "../../assets/pic_toB_inside_page_banner_1920.svg";
import pic_toC_event_page_banner_360 from "../../assets/pic_toC_event_page_banner_360.svg";
import pic_toC_event_page_banner_768 from "../../assets/pic_toC_event_page_banner_768.svg";
import pic_toC_event_page_banner_1440 from "../../assets/pic_toC_event_page_banner_1440.svg";
import pic_toC_event_page_banner_1920 from "../../assets/pic_toC_event_page_banner_1920.svg";
import pic_toS_event_page_banner_360 from "../../assets/pic_toS_event_home_page_banner_360.svg";
import pic_toS_event_page_banner_768 from "../../assets/pic_toS_event_home_page_banner_768.svg";
import pic_toS_event_page_banner_1440 from "../../assets/pic_toS_event_home_page_banner_1440.svg";
import pic_toS_event_page_banner_1920 from "../../assets/pic_toS_event_home_page_banner_1920.svg";
import pic_toB_event_page_banner_360 from "../../assets/pic_toB_event_home_page_banner_360.svg";
import pic_toB_event_page_banner_768 from "../../assets/pic_toB_event_home_page_banner_768.svg";
import pic_toB_event_page_banner_1440 from "../../assets/pic_toB_event_home_page_banner_1440.svg";
import pic_toB_event_page_banner_1920 from "../../assets/pic_toB_event_home_page_banner_1920.svg";
import logo_tag_easy_white_ver from "../../assets/logo_tag_easy_white_ver.svg";
import Carousel, { CarouselItem } from "../Carousel/index";
import Icon from '@mdi/react';
import { mdiMenu } from '@mdi/js';

const BannerLayer = (props) => {
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const imageRefs = useRef({});
  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    setImgDimensions({ width: naturalWidth, height: naturalHeight });
    setIsImageLoaded(true);
  };

  const aspectRatio = (imgDimensions.height / imgDimensions.width) * 100 || 56.25; 

  const navigate = useNavigate();
  const screenWidth = useScreen();
  const location = useLocation();
  const [currentType, setCurrentType] = useState("All");
  const [open, setOpen] = useState(false);

  // 預先加載所有圖片
  useEffect(() => {
    const preloadImages = () => {
      const images = [
        HomeBannerImg_360,
        HomeBannerImg_768,
        HomeBannerImg_1440,
        HomeBannerImg_1920,
        pic_toC_event_page_banner_360,
        pic_toC_event_page_banner_768,
        pic_toC_event_page_banner_1440,
        pic_toC_event_page_banner_1920,
        pic_toS_event_page_banner_360,
        pic_toS_event_page_banner_768,
        pic_toS_event_page_banner_1440,
        pic_toS_event_page_banner_1920,
        pic_toB_event_page_banner_360,
        pic_toB_event_page_banner_768,
        pic_toB_event_page_banner_1440,
        pic_toB_event_page_banner_1920,
        pic_toC_inside_page_banner_360,
        pic_toC_inside_page_banner_768,
        pic_toC_inside_page_banner_1440,
        pic_toC_inside_page_banner_1920,
        pic_toS_inside_page_banner_360,
        pic_toS_inside_page_banner_768,
        pic_toS_inside_page_banner_1440,
        pic_toS_inside_page_banner_1920,
        pic_toB_inside_page_banner_360,
        pic_toB_inside_page_banner_768,
        pic_toB_inside_page_banner_1440,
        pic_toB_inside_page_banner_1920
      ];
      images.forEach((src) => {
        const img = new Image();
        img.src = src;
        imageRefs.current[src] = img;
      });
    };
    preloadImages();
  }, []);

  // 處理當前圖片
  const handleCurrentImg = (type) => {
    if (type === "ToC") {
      if (screenWidth > 1440) {
        return HomeBannerImg_1920;
      }else if (screenWidth > 768) {
        return HomeBannerImg_1440;
      }else if (screenWidth > 600) {
        return HomeBannerImg_768;
      }else {
        return HomeBannerImg_360;
      }
    }
    if (type === "ToC_inner") {
      if (screenWidth > 1440) {
        return pic_toC_inside_page_banner_1920;
      }else if (screenWidth > 768) {
        return pic_toC_inside_page_banner_1440;
      }else if (screenWidth > 600) {
        return pic_toC_inside_page_banner_768;
      }else {
        return pic_toC_inside_page_banner_360;
      }
    }
    if (type === "ToS") {
      if (screenWidth > 1440) {
        return pic_toS_event_page_banner_1920;
      }else if (screenWidth > 768) {
        return pic_toS_event_page_banner_1440;
      }else if (screenWidth > 600) {
        return pic_toS_event_page_banner_768;
      }else {
        return pic_toS_event_page_banner_360;
      }
    }
    if (type === "ToS_inner") {
      if (screenWidth > 1440) {
        return pic_toS_inside_page_banner_1920;
      }else if (screenWidth > 768) {
        return pic_toS_inside_page_banner_1440;
      }else if (screenWidth > 600) {
        return pic_toS_inside_page_banner_768;
      }else {
        return pic_toS_inside_page_banner_360;
      }
    }
    if (type === "ToB") {
      if (screenWidth > 1440) {
        return pic_toB_event_page_banner_1920;
      }else if (screenWidth > 768) {
        return pic_toB_event_page_banner_1440;
      }else if (screenWidth > 600) {
        return pic_toB_event_page_banner_768;
      }else {
        return pic_toB_event_page_banner_360;
      }
    }
    if (type === "ToB_inner") {
      if (screenWidth > 1440) {
        return pic_toB_inside_page_banner_1920;
      }else if (screenWidth > 768) {
        return pic_toB_inside_page_banner_1440;
      }else if (screenWidth > 600) {
        return pic_toB_inside_page_banner_768;
      }else {
        return pic_toB_inside_page_banner_360;
      }
    }
    if (type === "ToC_activity") {
      if (screenWidth > 1440) {
        return pic_toC_event_page_banner_1920;
      }else if (screenWidth > 768) {
        return pic_toC_event_page_banner_1440;
      }else if (screenWidth > 600) {
        return pic_toC_event_page_banner_768;
      }else {
        return pic_toC_event_page_banner_360;
      }
    }
  }

  const handleLogin = () => {
    if (process.env.REACT_APP_ENV_NAME === "dev") {
      window.open('https://toeic-dev.tageasy.com.tw/', '_blank');
    } else {
      window.open('https://toeic.tageasy.com.tw/', '_blank');
    }
  }

  const handleReserve = () =>{
    window.location.href = 'https://www.colerate.com.tw/contact';
  }


  const handleLink = (type) => {
    if (type === "ToC") {
      navigate("/toeic");
      setOpen(false);
    } else if (type === "ToS") {
      navigate("/school");
      setOpen(false);
    } else if (type === "ToB") {
      navigate("/business");
      setOpen(false);
    } else if ( type === "activity"){
      navigate("/activity/202409");
      setOpen(false);
    }
  }


  const handleHomePage = () =>{
    navigate("/");
  }

  const handleOpenList = (newOpen) =>{
    setOpen(newOpen);
  }

  // 右上按鈕
  const RightButton = () =>{
    return(
      screenWidth > 768 ?
        <div className={styles.rightButtonBox} style={{top:currentType === "ToC" ? "2.7%" : "3%"}}>
          <div onClick={() => handleLink("ToC")} style={{ fontWeight: currentType === "ToC" ? "800" :"400" }}>多益刷題</div>
          <div onClick={() => handleLink("ToS")} style={{margin:"0px 20px", fontWeight: currentType === "ToS" ? "800" :"400"}}>校園方案</div>
          <div onClick={() => handleLink("ToB")} style={{ fontWeight: currentType === "ToB" ? "800" :"400" }}>企業合作</div>
        </div>
      :
      <div className={styles.rightButtonBox} style={{top:currentType === "ToC" ? "2.7%" : "3%", cursor:"pointer"}} onClick={()=>handleOpenList(true)}>
           <Icon path={mdiMenu} size={1.5} />
      </div>
    )
  }

  const DrawerList = (
    <Box sx={{ width: 150 }} role="presentation" onClick={() => handleOpenList(false)}>
      <List>
        {['多益刷題', '校園方案', '企業合作'].map((text) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={() => {
              if (text === '多益刷題') handleLink("ToC");
              else if (text === '校園方案') handleLink("ToS");
              else if (text === '企業合作') handleLink("ToB");
            }}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  // 跳轉時，更改當前 type
  useEffect(() => {
    const path = location.pathname;
    
    if (path.includes("/toeic")) {
      setCurrentType("ToC");
    } else if (path.includes("/school")) {
      setCurrentType("ToS");
    } else if (path.includes("/business")) {
      setCurrentType("ToB");
    } else {
      setCurrentType("All"); 
    }
  }, [location.pathname]); 

  return (
    <div
      className={styles.container}
      style={{ paddingBottom: props.url !== "Home" ? `${aspectRatio}%` : "0px" }}
    >
      {props.url === "Home" &&
        <>
          <div onClick={() => handleHomePage()} className={styles.leftButtonBox}>
            <img src={logo_tag_easy_white_ver}/>
          </div>
          <RightButton />
          <Drawer open={open} onClose={()=>handleOpenList(false)} anchor="right">
            {DrawerList}
          </Drawer>
          <Carousel>
            <CarouselItem>   
              <div style={{ cursor: "pointer", width:"100%" }} onClick={() => handleLink("activity")}>               
                {!isImageLoaded ? (
                    <div
                      style={{
                        width: "100%",
                        paddingTop: `${aspectRatio}%`,
                        backgroundColor: "#f0f0f0",
                      }}
                    ></div>
                  ):
                  <img
                  src={imageRefs.current[handleCurrentImg("ToC_activity")]?.src}
                  alt="Banner"
                  onLoad={handleImageLoad}
                />
                }
              </div>
            </CarouselItem>
            <CarouselItem> 
              <div style={{ cursor: "pointer", width:"100%" }} onClick={() => handleLink("ToC")}>           
                <img
                  src={imageRefs.current[handleCurrentImg("ToC")]?.src}
                  alt="Banner"
                  onLoad={handleImageLoad}
                />
              </div>
            </CarouselItem>
            <CarouselItem> 
              <div style={{ cursor: "pointer", width:"100%" }} onClick={() => handleLink("ToS")}>           
                <img
                  src={imageRefs.current[handleCurrentImg("ToS")]?.src}
                  alt="Banner"
                  onLoad={handleImageLoad}
                />
              </div>
            </CarouselItem>
            <CarouselItem> 
              <div style={{ cursor: "pointer", width:"100%" }} onClick={() => handleLink("ToB")}>           
                <img
                  src={imageRefs.current[handleCurrentImg("ToB")]?.src}
                  alt="Banner"
                  onLoad={handleImageLoad}
                />
              </div>
            </CarouselItem>
          </Carousel>
        </>
      }
      {props.url === "ToC" &&
        <>
          {
            isImageLoaded &&
            <>
              <div onClick={() => handleHomePage()} className={styles.leftButtonBox} style={{top:"1.8%"}}>
                <img src={logo_tag_easy_white_ver}/>
              </div>
              <RightButton />
              <Drawer open={open} onClose={()=>handleOpenList(false)} anchor="right">
                {DrawerList}
              </Drawer>
              <div onClick={() => handleLogin()} className={`${screenWidth > 600 ? styles.TrialButtonBox : styles.TrialButtonBox_Phone} btn_register`}>
                <div>立即免費註冊</div>
              </div>
            </>
          }
          <img src={handleCurrentImg("ToC_inner")} alt="Banner"  onLoad={handleImageLoad} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', visibility: isImageLoaded ? 'visible' : 'hidden', }}/>
        </>
      }
      {props.url === "ToS" &&
        <>
          {
            isImageLoaded &&
            <>
              <div onClick={() => handleHomePage()} className={styles.leftButtonBox}>
                <img src={logo_tag_easy_white_ver}/>
              </div>
              <RightButton />
              <Drawer open={open} onClose={()=>handleOpenList(false)} anchor="right">
                {DrawerList}
              </Drawer>
              <div onClick={() => handleReserve()} className={screenWidth > 600 ? styles.TrialButtonBox_ToS : styles.TrialButtonBox_Phone}>
                <div>預約體驗</div>
              </div>
            </>
          }
          <img src={handleCurrentImg("ToS_inner")} alt="Banner"  onLoad={handleImageLoad} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', visibility: isImageLoaded ? 'visible' : 'hidden', }}/>
        </>
      }
      {props.url === "ToB" &&
        <>
          {
            isImageLoaded &&
            <>
              <div onClick={() => handleHomePage()} className={styles.leftButtonBox}>
                <img src={logo_tag_easy_white_ver}/>
              </div>
              <RightButton />
              <Drawer open={open} onClose={()=>handleOpenList(false)} anchor="right">
                {DrawerList}
              </Drawer>
              <div onClick={() => handleReserve()} className={screenWidth > 600 ? styles.TrialButtonBox_ToB : styles.TrialButtonBox_Phone}>
                <div>預約體驗</div>
              </div>
            </>
          }
          <img src={handleCurrentImg("ToB_inner")} alt="Banner"  onLoad={handleImageLoad} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', visibility: isImageLoaded ? 'visible' : 'hidden', }}/>
        </>
      }
    </div>
  );
}

export default BannerLayer;
